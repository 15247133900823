/* @import 'react-datepicker/dist/react-datepicker.css'; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.noGap {
  padding: 0;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
#accessesbility td {
  padding: 10px 0 0 18px;
  font-size: 12px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toggle-slide {
  max-width: 220px !important;
  min-width: 100px !important;
}

.card-aside {
  width: 230px !important;
}
.mandatory {
  color: red;
}

.ag-theme-quartz {
  --ag-grid-size: 5px;
  --ag-list-item-height: 20px;
}

.ag-theme-quartz.compact,
.ag-theme-quartz-dark.compact {
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px;
  --ag-font-size: 10px;
}

.ag-theme-quartz .ag-row:nth-child(odd) {
  background-color: #fff; /* Light gray background for odd rows */
}

.ag-theme-quartz .ag-row:nth-child(even) {
  background-color: #f4f4f5; /* White background for even rows */
}

/* Filter input focus styles */
.ag-theme-quartz .ag-filter-wrapper input:focus,
.ag-theme-quartz .ag-floating-filter-input input:focus {
  border-color: #2263b3; /* Change border color to blue on focus */
  box-shadow: 0 0 5px rgba(0, 0, 255, 0.5); /* Add blue shadow on focus */
  outline: none; /* Remove the default outline */
}
/* custom-ag-grid.css */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.ag-theme-quartz {
  font-family: "Roboto", sans-serif;
}

/* Optional: Customize specific elements if needed */
.ag-theme-quartz .ag-header-cell-label {
  font-family: "Roboto", sans-serif;
}

.ag-theme-quartz .ag-cell,
pre,
.ant-modal-header,
.ant-modal-title,
.ant-modal-body,
.ant-drawer .ant-drawer-body,
.ant-table-cell,
.ant-popover-inner-content,
.ant-select-item-option-content,
.ant-collapse-header-text,
.ant-descriptions-item-content {
  font-family: "Roboto", sans-serif !important;
}

.ag-theme-quartz .ag-header-row {
  background-color: #fff;
}

.ag-theme-quartz .ag-header-cell-label {
  font-family: "Roboto", sans-serif;
}
.button {
  cursor: pointer;
}

.nk-block-head-lg {
  padding-bottom: 1em;
}

h6 {
  color: #004293;
}

.nk-menu .nk-menu-link,
.nk-menu .nk-menu-sub .nk-menu-link,
.link-list-menu a,
.overline-title-alt {
  color: #000;
}
/* customStyles.css */
.wrap-header .ag-header-cell-label {
  white-space: normal !important; /* Allow wrapping of text */
  line-height: normal; /* Adjust line height for better readability */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}

/* Optional: Increase the header height if needed */
.ag-header-cell {
  align-items: center;
}

.ag-header-cell-text {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}

.wrap-header .rdt_TableHeadRow .rdt_TableCol {
  white-space: normal !important;
  line-height: 1.5;
  word-wrap: break-word;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#customDataItems .data-item {
  padding: 0.5rem 1.25rem;
}

.customLabel {
  color: #000;
}
.diagram-component {
  width: 100%;
  height: 600px;
  border: 1px solid #d3d3d3;
}
@media (max-width: 2000px) {
  body {
    font-size: 12px;
  }
  .bfvmBl {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .nk-content-body > .nk-block-head-sm:first-child {
    padding-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .card-inner-lg {
    padding: 1.1rem;
  }
}

@media (min-width: 992px) {
  .page-title {
    font-size: 1.25rem;
  }
}
.nk-header-fixed + .nk-content {
  margin-top: 45px;
  position: relative;
}
.cardBox .card-inner-xl {
  padding: 1.5rem !important;
}

.sub-text {
  font-size: 14px;
  font-weight: 600;
}
#poForms h5 {
  text-transform: unset !important;
  font-size: 16px !important;
  columns: #333;
}

.prxColor {
  font-size: 18px;
}
.prxColor:hover {
  color: #61dafb;
}

.form-label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-label {
  margin-right: 8px;
}
.poTable {
  margin-top: 20px;
  font-size: 14px;
}

.table-striped input {
  width: 100%;
  border: 1px solid #e1e1e1;
}

/* Ensure input fields take full width of their container */
.table-responsive input {
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Remove padding from table cells */
.poTable {
  border: 0;
}
.poTable td {
  padding: 0;
  border: 0;
}
.poTable th {
  color: #2263b3;
  /* background-color: #2263b3; */
  background-color: #e7eef7;
  /* opacity: 0.9; */
  /* color: #fff; */
  /* border: 0; */
}
.purchaseOrderTableStyle {
  border-collapse: collapse;
  width: 100%;
  /* border: 1px solid #dbdfea; */
}
.purchaseOrderTableStyle tr {
  border-radius: 25px;
}
.purchaseOrderTableStyle td {
  border: 2px solid #fff;
  /* border: 1px solid #dbdfea; */
  text-align: center;
  padding: 10px 8px 8px 8px;
}
.purchaseOrderTableFooter td {
  border: 1px solid transparent;
}
.purchaseOrderTableCellStyle {
  text-align: center;
  border: 2px solid #fff;
}
.r_align {
  text-align: right !important;
}

.sigCanvas {
  height: 100%;
  width: 500px;
}

.pdf-div {
  padding: 50px 0;
  background-color: #dddddd;
  margin-top: 25px;
  width: 100%;
}
canvas.react-pdf__Page__canvas {
  width: 90vw;
  border: 1px solid black;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

.ant-table-cell {
  padding: 5px !important;
  /* text-align: center !important; */
}
.ant-select-arrow {
  /* display: none !important; */
}
.ant-select-selector {
  border: 0.8px solid rgb(219, 223, 234) !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.inline-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.inline-select .form-group {
  width: 80%;
  margin-left: 15px;
}

.ant-table-wrapper .ant-table.ant-table-middle *::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.ant-table-wrapper .ant-table.ant-table-middle *::-webkit-scrollbar-track {
  background-color: white !important;
}

.ant-table-wrapper .ant-table.ant-table-middle *::-webkit-scrollbar-thumb {
  background-color: #c4c8cc !important;
  border-radius: 5px !important;
}

.ant-table-wrapper
  .ant-table.ant-table-middle
  *::-webkit-scrollbar-thumb:hover {
  background-color: #979899 !important;
}

.ant-table .ant-table-header.ant-table-hide-scrollbar::-webkit-scrollbar {
  width: 20px !important;
}

*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

*::-webkit-scrollbar-track {
  background-color: white !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c8cc !important;
  border-radius: 5px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #979899 !important;
}

.paddingLeft10 {
  padding-left: 10px;
}

.data-label {
  color: #000;
  font-weight: 500;
}

.addNewItem {
  margin: 10px;
  cursor: pointer;
  color: black;
  font-size: 16px;
}

.data-label {
  color: #000;
  font-weight: 500;
}

.signatureBtnWrapper {
  border: 1px solid #e5ecf7;
  background-color: #f7faff;
  padding: 10px;
  margin-left: 10px;
  width: 22%;
  border-radius: 5px;
}

.signatueImgWrapper {
  overflow: hidden;
  min-height: 120px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  margin: 5px 0;
  align-items: center;
}

.paddingLeft10 {
  padding-left: 10px;
}
.ant-popover {
  z-index: 100000 !important;
}
.ant-picker-now-btn {
  display: none;
}

.alignPostions {
  position: relative;
}
.alignPostions a {
  position: absolute;
  cursor: pointer;
  right: 45px;
  font-weight: bold;
  color: #61dafb !important;
  z-index: 1000;
}

.expandableBody {
  display: flex;
  justify-content: flex-start;
}
.lineAddress li {
  padding-bottom: 3px;
  text-align: left;
}
.lineAddress h6 {
  text-align: left;
  font-size: 14px;
}
.lineAddress {
  border-left: 1px solid #ccc;
  padding-left: 15px;
}

.error-panel .ant-collapse-header {
  color: red;
}

.custom-description-title .ant-descriptions-item-label {
  color: #333;
  font-weight: bold;
}
.billingAddress_align p {
  margin-bottom: 5px;
}

.approval-timeline .ant-timeline-item-content {
  font-size: 12px;
}

.approval-timeline .ant-timeline-item-content .designation {
  font-size: 12px;
  color: grey;
}
.ant-drawer {
  z-index: 1020 !important;
}

#customTable th {
  text-align: left !important;
}

.pageLoader {
  height: 80vh;
  margin: auto;
}

/* ------------------ Ant desing overrides -----------------*/
.ant-input-outlined,
.ant-input-number .ant-input-number-input {
  /* display: block !important; */
  width: 100% !important;
  padding: 0.4375rem 1rem !important;
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  line-height: 1.25rem !important;
  color: #3c4d62 !important;
  appearance: none !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #dbdfea !important;
  border-radius: 4px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  margin: 0 !important;
  font-family: "Roboto", sans-serif !important;
}
.ant-select-selector {
  font-family: "Roboto", sans-serif !important;
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  color: #3c4d62 !important;
}
.ant-input-outlined:focus,
.ant-input-number-outlined:focus-within {
  border-color: #2263b3 !important;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(34, 99, 179, 0.1);
}
.ant-form-item-label > label {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #344357 !important;
  margin-bottom: 0.5rem !important;
}
.ant-input-number-handler-wrap {
  display: none !important;
  margin: 0 !important;
  font-family: inherit !important;
}
.dashboard-side-widget .ant-card-body {
  padding: 12px !important;
}
.dashboard-side-widget .card-amount .amount {
  font-size: 1rem !important;
}
.dashboard-main-graph div:first-child {
  width: 100% !important;
}

.ant-popover {
  z-index: 1000;
}
/* -------------------------------  Login Stles  */
.login-container {
  min-height: 91vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login-bgi {
  background-image: url("./images/loginPage-bgi.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  height: 130px;
  z-index: 0;
}

.login-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.header-area.title {
  font-size: 42px !important;
  line-height: 58px !important;
  color: #000;
  width: 50%;
  height: 100%;
  text-align: center;
  margin: auto;
  font-weight: 900;
}
.login-previewCard {
  z-index: 2;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

/* -----------------------------  Login Stles Ends */

.ant-collapse-header {
  align-items: center !important;
}

.form-control-select:after {
  display: none !important;
}

.pac-container {
  z-index: 9999999999;
}

span.ant-upload.ant-upload-btn {
  padding: 4px !important;
}

.ant-upload-drag-custom.ant-upload.ant-upload-drag,
.css-dev-only-do-not-override-p8b6i.ant-upload.ant-upload-drag,
.ant-upload-drag-custom > .ant-upload-drag {
  height: 48px !important;
}

.inlineTableCellLink {
  color: #2263b3;
  cursor: pointer;
  font-weight: 500;
}

.inlineTableCellLink:hover {
  text-decoration: underline;
  color: #143a69;
}

.low-internet-screen {
  height: 100vh;
  background-color: rgb(238 235 235 / 31%);
  position: fixed;
  z-index: 99999;
  width: 100%;
  cursor: not-allowed;
}
