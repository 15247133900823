/// Logo
/////////////////////////
.logo{
    &-link{
        position: relative;
        display: inline-block;
        align-items: center;
    }
    &-dark{
        opacity: 1;
        .tc-light &,.is-dark &,.is-theme &{
            opacity: 0;
        }
    }
    &-light{
        opacity: 0;
        .tc-light &,.is-dark &,.is-theme &{
            opacity: 1;
        }
    }
    &-img{
        max-height: $logo-height;
        &-lg{
            max-height: $logo-height-lg;
        }
        &-sm{
            max-height: $logo-height-sm;
        }
        &-icon{
            max-height: $logo-height-icon;
        }
        &:not(:first-child){
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

/// Headers
////////////////////////////
@mixin icon-active {
    opacity: 1;
    height: 116%;
    width: 116%;
}
.nk{
    &-header{
        background: $header-bg-color;
        border-bottom: 1px solid $header-border-bottom;
        padding: 0 $header-main-gap-x;
        z-index: 1010;
        @if($dark_option==true) {
            &.is-dark:not([class*=bg-]){
                background: $header-bg-dark-color;
                border-color: $header-border-dark-bottom;
            }
        }
        &.is-light:not([class*=bg-]){
            background: $white;
        }
        @if($dark_theme_option==true) {
            &.is-theme:not([class*=bg-]){
                background: $header-bg-theme-color;
                border-color: $header-border-theme-bottom;
            }
        }
        &-fixed{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            min-width: $header-min-width;
            + .nk-content {
                margin-top: $header-height;
                position: relative;
            }
        }
        &-wrap{
            position: relative;
            display: flex;
            align-items: center;
            margin: 0 -.25rem;
            > *{
                padding:0 .25rem;
            }
        }
        &-right{
            align-items: center;
            justify-content: flex-end;
        }
        &-center{
            justify-content: center;
        }
        &-tools{
            margin-left: auto;
            padding-top: $header-main-gap-y;
            padding-bottom: $header-main-gap-y;
        }
        &-brand{
            flex-shrink: 0;
            padding-top: $header-main-gap-y;
            padding-bottom: $header-main-gap-y;
        }
        .dropdown-menu{
            .lead-text{
                color: $base-color;
            }
            .sub-text,.overline-title,.overline-title-alt{
                color: $base-light;
            }
        }
    }
    &-quick{
        &-nav{
            display: flex;
            align-items: center;
            margin: 0 -6px;
            > li{
                padding: 0 6px;
                &.user-dropdown > a {
                    padding: 0 2px;
                }
            }
            &-icon{
                display: inline-flex;
                position: relative;
                font-size: 1.5rem;
                z-index: 1;
                color: $base-text;
                padding: .375rem;
                @if($dark_option==true) {
                    .is-dark &{
                        color: lighten($base-light, 10%);
                    }
                }
                @if($dark_theme_option==true) {
                    .is-theme &{
                        color: $accent-light;
                    }
                    &.nk-nav-toggle{
                        .is-theme &{
                            color: $white;
                        }
                    }
                }
                &:focus{
                    box-shadow: none;
                }
                &:before{
                    position: absolute;
                    z-index: -1;
                    height: 20px;
                    width: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transform-origin: 50% 50%;
                    content: '';
                    background-color: $light-200;
                    border-radius: 50%;
                    opacity: 0;
                    transition: all .3s;
                    @if($dark_option==true) {
                        .is-dark &{
                            background-color: darken($darker,3%);
                        }
                    }
                    @if($dark_theme_option==true) {
                        .is-theme &{
                            background-color: darken($accent-dark,10%);
                        }
                    }
                    .show > &{
                        @include icon-active();
                    }
                }
                &:hover:before,&.active:before{
                    @include icon-active();
                }
            }
        }
    }
}
@media (max-width:359px){
    .nk{
        &-quick{
            &-nav{
                margin: 0 -3px;
                > li{
                    padding: 0 3px;
                }
            }
        }
    }
    .hide-mb-xs{
        display: none;
    }
}
@media (max-width:575px){
    .hide-mb-sm{
        display: none;
    }
}

@include media-breakpoint-up(sm){
    .nk{
        &-header{
            padding: 0 $header-main-gap-x-sm;
        }
        &-quick{
            &-nav{
                margin: 0 -10px;
                > li{
                    padding: 0 10px;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg){
    .nk{
        &-header{
            &-fluid{
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}
@media (min-width:1660px){
    .nk{
        &-header{
            &-fluid{
                padding-left: 44px;
                padding-right: 44px;
            }
        }
    }
}
@keyframes overlay-fade-in{
    0%{
        opacity: 0;
    }100%{
        opacity: 1;
    }
}
.nk-header{
    &-menu{
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        width: 280px;
        height: 100vh;
        max-height: 100vh;
        z-index: 999;
        background: $white;
        &-inner{
            overflow: auto;
            max-height: 100%;
            min-height: 100%;
        }
        &.mobile-menu{
            padding-top: 65px;
            padding-bottom: 2.5rem;
            padding-left: 0;
            padding-right: 0;
            transition: transform .4s ease;
        }
        &.nk-header-active{
            transform: translateX(0);
        }
    }
    &-mobile{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 28px;
        border-bottom: 1px solid $border-light;
        .logo-dark {
            opacity: 1;
        }
        .logo-light {
            opacity: 0;
        }
        .nk-nav-toggle.nk-quick-nav-icon{
            color: $base-text;
            &:before{
                background-color: $light-200;
            }
        }
    }
    &-overlay{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $sidebar-overlay;
        z-index: 900;
        animation: overlay-fade-in .4s ease 1;
    }
}
@include media-breakpoint-up(lg){
    .nk-header{
        &-menu{
            .nk-header-mobile{
                display: none;
            }
            position: static;
            background: transparent !important;
            height: auto;
            max-height: auto;
            border-right: none;
            width: auto;
            padding: 0;
            overflow: visible;
            transform: translateX(0) !important;
        }
        &-overlay{
            display: none;
        }
    }
}

nk-quick-nav-icon {
    position: relative;
    // display: inline-block;
    animation: none;
  }
  
  .nk-quick-nav-icon.highlighted {
    animation: bounce 1s infinite;
    // box-shadow: 0 0 15px 5px rgba(76, 175, 80, 0.5);
  }
  
  .highlight-dot {
    position: absolute;
    top: -2px;
    right: 3px;
    width: 7px;
    height: 7px;
    background-color: #4caf50;
    border-radius: 50%;
    box-shadow: 0 0 8px #4caf50;
    animation: pulse 1.5s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  

  .icon-status {
    position: relative;
  }
  .icon-status:after {
    border: none !important; /* Override the border */
  }
  /* Red notification dot with bounce animation */
  .notification-dot {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    // border: 2px solid white;
    animation: bounceNotification 1s infinite ease-in-out; /* Add bounce animation */
  }
  
  /* Vibration animation for the bell icon */
  @keyframes vibrate {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  /* Bounce animation for the dot */
  @keyframes bounceNotification {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }

  .icon-status.vibrating {
    animation: vibrate 0.5s infinite;
  }
